import React from "react";
import Banner1 from "./../../assets/foro-go/banner/banner_fechas.webp";
import Banner2 from "./../../assets/foro-go/banner/banner_preventa.webp";
import Banner3 from "./../../assets/foro-go/banner/banner_speakers.webp";
import Slider from "react-slick";

export default function Carousel() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500, // Tiempo en milisegundos entre transiciones
    pauseOnHover: false,
    arrows: false,
    fade: true,
  };

  return (
    <section
      id="banner"
      style={{
        width: "auto",
        height: "100vh",
        background: "#000",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="about-area ptb-120 bg-black "
    >
      <div className="container">
        <div className="row h-100 align-items-center">
          <Slider {...settings}>
            <div className="item">
              <img alt="" height="auto" width="auto" src={Banner2} />
            </div>

            <div className="item">
              <img alt="" height="auto" width="auto" src={Banner1} />
            </div>

            <div className="item">
              <img alt="" height="auto" width="auto" src={Banner3} />
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}
