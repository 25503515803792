import React from "react";
import "./styles.css";

const FloatingWhatsAppButton = () => {
  const whatsappLink = `https://wa.me/+524778057498`;
  /* eslint-disable */

  const openWhatsApp = () => {
    window.open(whatsappLink, "_blank");
  };

  return (
    <div
      className="floating-whatsapp-button"
      onClick={openWhatsApp}
      title="¡Contáctame por WhatsApp!"
    >
      <i className="bi bi-whatsapp"></i>
    </div>
  );
};

export default FloatingWhatsAppButton;
